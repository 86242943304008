<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              <div>
                {{ info.orderSn }}
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单机构">
              <div>{{ info.orderCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="买方">
              <div>{{ info.purchaserCorporationName }}</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="卖方">
              <div>{{ info.producerCorporationName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单类型">
              {{ info.orderTypeValue }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单时间">
              {{ tsFormat(info.createTime) }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单总额">
              {{ info.factoryTotalPrice }} 元
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="订单状态">
              <span
                v-if="
                  fromRouterName === 'ToDeliverOrderList' ||
                    storeRouterName === 'ToDeliverOrderList' ||
                    fromRouterName === 'PublicDeliver' ||
                    storeRouterName === 'PublicDeliver'
                "
              >
                {{ info.orderStatusValue }}
              </span>
              <span v-else>
                {{ info.nodeStatus }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="交付周期">
              {{ info.orderCycle || " - " }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="info.orderTypeValue === '远期订单'">
            <a-form-item label="下定月份">
              <div>{{ info.installmentSn }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              <div>{{ info.orderMemo || " - " }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">收货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收货人姓名">
              <div>{{ info.consigneeRealName }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货人电话">
              <div>{{ info.consigneeTel }}</div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收货地址">
              <div>{{ info.consigneeAddress }}</div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div
        class="container__title between"
        v-if="info.productTypeValue === '整车'"
      >
        <div>商品信息（整车）</div>
      </div>
      <div class="container__title" v-else>商品信息（配件）</div>
      <a-table
        class="commodity__table"
        :columns="commodityColumns"
        :data-source="calculate(info.instantOrderSkus)"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <div
          slot="skuImages"
          v-viewer
          slot-scope="text, record"
          class="img__container"
        >
          <img
            :src="imageBaseUrl + record.skuPic"
            :data-source="imageBaseUrl + record.skuPic"
            alt="商品图"
            v-if="record.skuName !== '合计：'"
          />
        </div>
        <template slot="quantityShipped" slot-scope="text">
          {{ text || "0" }}
        </template>
      </a-table>
    </section>
    <section class="card__container">
      <div class="container__title">合同信息</div>
      <a-table
        class="contract__table"
        :columns="contractColumns"
        :data-source="info.contracts"
        :loading="loading"
        :pagination="false"
        rowKey="contractNo"
      >
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            :href="imageBaseUrl + record.contractFile.fileUrl"
            v-if="record.contractFile"
            target="_blank"
          >
            预览
          </a>
        </template>
      </a-table>
    </section>
    <section class="card__container" :class="saleBillNum === 0 ? 'bottom' : ''">
      <div class="container__title">付款信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="流水号">
              {{ payInfo.huifuSeqId || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="付款机构">
              {{ payInfo.paymentCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收款机构">
              {{ payInfo.receiveCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="收款金额">
              ¥{{ payInfo.paymentAmount }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="付款时间">
              {{ payInfo.paymentTime ? tsFormat(payInfo.paymentTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="状态">
              {{ payInfo.paymentStatus }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section
      class="card__container"
      :class="
        info.orderStatusValue === '待发货' ||
        info.orderStatusValue === '部分发货'
          ? 'bottom'
          : 'low__bottom'
      "
      v-if="saleBillNum > 0"
    >
      <div class="container__title">
        物流信息
        <span v-if="saleBillNum > 0">（{{ saleBillNum }}）条</span>
        <a
          class="check__all"
          v-if="saleBillNum > 1"
          @click="onCheckDeliverDetail"
        >
          查看全部
        </a>
      </div>
      <div class="sale__title">第一批明细：</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="发货时间">
              {{
                saleBillInfo.logisticsSendTime
                  ? tsFormat(saleBillInfo.logisticsSendTime)
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="商品金额">
              {{ calculatePrice(inventoryData) }} 元
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流公司">
              {{
                saleBillInfo.logisticsName ? saleBillInfo.logisticsName : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="物流单号">
              {{ saleBillInfo.logisticsSn ? saleBillInfo.logisticsSn : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="物流电话">
              {{ saleBillInfo.logisticsTel ? saleBillInfo.logisticsTel : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="发货清单">
              <a-table
                :columns="inventoryColumns"
                :data-source="inventoryData"
                :loading="inventoryLoading"
                :pagination="false"
                :rowKey="(record, index) => index"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="receiveBill && receiveBill.length > 0">
          <div v-for="item in receiveBill" :key="item.receivingId">
            <a-row>
              <a-col :span="8">
                <a-form-item label="收货时间">
                  {{ item.signTime ? tsFormat(item.signTime) : "-" }}
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="发票信息">
                  <a>查看</a>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="商品金额">
                  {{ calculatePrice(item.deliverVoList) }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="收货清单">
                  <a-table
                    :columns="inventoryColumns"
                    :data-source="item.deliverVoList"
                    :pagination="false"
                    :rowKey="(record, index) => index"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-form>
    </section>
    <section
      class="examine__container"
      v-if="
        info.orderStatusValue === '待发货' ||
          info.orderStatusValue === '部分发货'
      "
    >
      <div>
        <a-button type="primary" @click="onDeliver(orderSn, info.orderType)">
          发货
        </a-button>
      </div>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import { tsFormat } from "@/components/DateUtils";
import {
  fetchOrderDetail,
  fetchPayResult
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";
import {
  fetchReceiveBillBySaleBill,
  fetchSaleBillByOrderSn
} from "@/services/OrderService";

const commodityColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "25%"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "20%"
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: "15%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "商品数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "已发货数量",
    dataIndex: "quantityShipped",
    scopedSlots: { customRender: "quantityShipped" },
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "10%"
  }
];

const contractColumns = [
  {
    title: "签署方1",
    dataIndex: "partyFirst",
    width: "20%"
  },
  {
    title: "签署方2",
    dataIndex: "partySecond",
    width: "20%"
  },
  {
    title: "合同名称",
    dataIndex: "contractName",
    width: "30%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

const inventoryColumns = [
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "40%"
  },
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品序列号",
    dataIndex: "goodsSn",
    width: "15%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "商品数量",
    dataIndex: "quantity",
    width: "15%"
  }
];

export default {
  name: "ToDeliverOrderDetail",
  components: {
    Breadcrumb,
    BreadcrumbItem
  },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      info: {},
      tsFormat,
      commodityColumns,
      loading: false,
      imageBaseUrl,
      contractColumns,
      saleBillInfo: {},
      saleBillNum: 0,
      inventoryColumns,
      inventoryData: [],
      inventoryLoading: false,
      receiveBill: [],
      payInfo: {},
      fromRouterName: "",
      storeRouterName: ""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromRouterName = from.name;
      if (vm.fromRouterName) {
        sessionStorage.setItem("fromRouterName", vm.fromRouterName);
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("fromRouterName");
    next();
  },
  mounted() {
    if (sessionStorage.getItem("fromRouterName")) {
      this.storeRouterName = sessionStorage.getItem("fromRouterName");
    }
    this.loadDetail();
    this.loadSaleBill();
    this.loadPayBill();
  },
  methods: {
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "deliver"
      };
      fetchOrderDetail(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.info = resp.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 发货
    onDeliver(orderSn, orderType) {
      this.$router.push({
        name: "PublicDeliver",
        params: { orderSn: orderSn, type: orderType }
      });
    },
    // 转化下定月份
    transferOrderMonth(obj) {
      let str = "";
      let list = [];
      for (const key in obj) {
        list.push(key);
      }
      if (list.length > 0) {
        str = list.join(",");
      } else {
        str = " - ";
      }
      return str;
    },
    // 获取发货单
    loadSaleBill() {
      this.inventoryLoading = true;
      fetchSaleBillByOrderSn(this.orderSn)
        .then(resp => {
          this.inventoryLoading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data && data.length > 0) {
              let list = data;
              this.saleBillNum = list.length;
              this.saleBillInfo = list[0];
              this.inventoryData = this.saleBillInfo.detailVoList;
              this.loadReceiveBill(this.saleBillInfo.consignmentId);
            }
          }
        })
        .catch(() => {
          this.inventoryLoading = false;
        });
    },
    // 获取收货单
    loadReceiveBill(consignmentId) {
      fetchReceiveBillBySaleBill(consignmentId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.receiveBill = resp.data.data;
        }
      });
    },
    // 计算商品金额
    calculatePrice(list) {
      let num = 0;
      if (list && list.length > 0) {
        list.forEach(item => {
          num = num + item.skuFactoryPrice * item.quantity;
        });
        num = num.toFixed(2);
      } else {
        num = "-";
      }
      return num;
    },
    // 查看全部物流
    onCheckDeliverDetail() {
      this.$router.push({
        name: "DeliverDetail",
        params: { orderSn: this.orderSn }
      });
    },
    // 计算
    calculate(list) {
      let arr = [];
      if (list && list.length > 0) {
        arr = [...list];
        let num = 0;
        let deliverNum = 0;
        let allPrice = 0;
        list.forEach(item => {
          console.log();
          num = num + parseInt(item.purchaseQuantity);
          if (item.quantityShipped) {
            deliverNum = deliverNum + parseInt(item.quantityShipped);
          }
          allPrice = allPrice + parseFloat(item.skuFactoryTotalPrice);
        });
        const params = {
          skuName: "合计：",
          purchaseQuantity: num,
          quantityShipped: deliverNum,
          skuFactoryTotalPrice: allPrice.toFixed(2)
        };
        arr.push(params);
      }
      return arr;
    },
    // 查付款单
    loadPayBill() {
      fetchPayResult(this.orderSn).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.payInfo = data.find(
            ele =>
              ele.receiveCorporationId === localStorage.getItem("corporationId")
          );
        }
      });
    }
  }
};
</script>

<style scoped>
.container__title {
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  font-weight: bold;
}

/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commodity__table,
.contract__table {
  margin-top: 20px;
}

.examine__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 10px 20px 220px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.19);
}

.examine__container div {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 10px;
}

.bottom {
  margin-bottom: 90px;
}

.low__bottom {
  margin-bottom: 30px;
}

.img__container {
  width: 100px;
  height: 100px;
}

.img__container img {
  width: 100px;
  object-fit: cover;
}

.check__all {
  margin-left: 15px;
}

.sale__title {
  margin-top: 20px;
  font-weight: bold;
}
</style>
